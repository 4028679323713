import Axios from 'axios'
import TransactionService from './transaction.service'

export default {
  pollJob(state, job) {
    const config = ('netdb_axios_config' in state) ? state.netdb_axios_config : state
    config.headers['Content-Type'] = 'application/json; charset=utf-8'
    return Axios.get('/api/macfinder/job/' + job, config)
  },
  createJob(state, bcd, mac) {
    const config = ('netdb_axios_config' in state) ? state.netdb_axios_config : state
    config.headers['Content-Type'] = 'application/json; charset=utf-8'
    return Axios.post('/api/macfinder/job', { bcd: bcd, mac: mac }, config)
  },
  ouiLookup(mac) {
    return Axios.get('/api/macfinder/oui_lookup?mac=' + mac)
  },
  resolveOutlet(state, bcd, device, port) {
    const ta = [
      { name: 'nd.bcd.list', old: { name: bcd } }, // BCDs
      { name: 'nd.vlan.list', inner_join_ref: { bcd_list: 'default' } },
      { name: 'nd.l_port.list', inner_join_ref: { vlan_list: 'default' }, old: { dev_fqdn: device } },
      { name: 'nd.l2p_port.list', inner_join_ref: { l_port_list: 'default' } },
      { name: 'nd.p_port.list', inner_join_ref: { l2p_port_list: 'default' }, old: { name: port } },
      { idx: 'tmp', name: 'nd.p_port.list', inner_join_ref: { p_port_list: 'self' } },
      {
        idx: 'resolved',
        name: 'nd.p_port.list',
        old: {
          is_edge_node: true
        },
        inner_join_ref: {
          p_port_list: 'api_func_nd_p_port_is_contained_by_conn_id_gfk_list'
        },
        anti_join_ref: {
          tmp: 'self'
        }
      }
    ]
    return TransactionService.execute(state, ta)
  }
}
