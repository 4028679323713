<template>
  <div class="macfinder">
    <h1>{{ $t('system.macvendor_search') }} ({{ $t('views.tools.oui_lookup.oui_lookup') }})</h1>
    <p>{{ $t('views.tools.oui_lookup.lookup_note') }}</p>
    <b-form @submit="createJob">
      <b-form-group :label="$t('system.mac_address')" :description="$t('views.tools.oui_lookup.any_format_possible')">
        <b-input required v-model="search_mac" :placeholder="$t('system.mac_address')"></b-input>
      </b-form-group>
      <b-button type="submit" variant="outline-success">
        <netvs-icon icon="search"></netvs-icon>
        {{ $t('system.search') }}
      </b-button>
    </b-form>
    <hr/>
    <b-jumbotron v-if="result_mac" :header="vendor||$t('views.tools.oui_lookup.no_manufacture')"
                 :lead="result_mac"></b-jumbotron>
  </div>
</template>
<script>
import MACfinderService from '@/api-services/macfinder.service'

export default {
  name: 'OUILookup',
  data() {
    return {
      search_mac: null,
      result_mac: null,
      vendor: null
    }
  },
  methods: {
    async createJob(ev) {
      ev.preventDefault()
      try {
        const res = await MACfinderService.ouiLookup(this.search_mac)
        this.vendor = res.data.vendor
        this.result_mac = res.data.mac
      } catch (e) {
        if (e.request.status === 404) {
          this.vendor = null
          this.result_mac = e.response.data.mac
        } else {
          throw e
        }
      }
      this.search_mac = ''
      return true
    }
  }
}
</script>
